import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { languageInitializer } from 'src/environments/environment';
const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  selected = '';

  constructor(private translate: TranslateService, private storage: Storage, private plt: Platform) { }

  setInitialAppLanguage() {
    const language = languageInitializer.selectedLang;
    this.translate.setDefaultLang(language);
    this.setLanguage(language);

  }

  currentLanguage() {
    return languageInitializer.selectedLang
  }

  setLanguage(lng) {
    this.storage.set(LNG_KEY, lng);
    if (lng == "Arabic" || lng == "ar") {
      lng = "ar";
    } else {
      lng = "en";
    }
    this.translate.setDefaultLang(lng);
    this.translate.use(lng);
    this.selected = lng;
    languageInitializer.selectedLang = lng
  }
}
