import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'company-vehicle',
    loadChildren: () => import('./company-vehicle/company-vehicle.module').then(m => m.CompanyVehiclePageModule)
  },
  {
    path: 'add-delar',
    loadChildren: () => import('./add-delar/add-delar.module').then(m => m.AddDelarPageModule)
  },
  {
    path: 'device-commands',
    loadChildren: () => import('./device-commands/device-commands.module').then(m => m.DeviceCommandsPageModule)
  },
  {
    path: 'new-dashboard',
    loadChildren: () => import('./new-dashboard/new-dashboard.module').then(m => m.NewDashboardPageModule)
  },
  {
    path: 'device-activation',
    loadChildren: () => import('./device-activation/device-activation.module').then(m => m.DeviceActivationPageModule)
  },
  {
    path: 'vehicle-creation',
    loadChildren: () => import('./vehicle-creation/vehicle-creation.module').then(m => m.VehicleCreationPageModule)
  },
  {
    path: 'stock-uploader',
    loadChildren: () => import('./stock-uploader/stock-uploader.module').then(m => m.StockUploaderPageModule)
  },
  {
    path: 'stocks',
    loadChildren: () => import('./stocks/stocks.module').then(m => m.StocksPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionPageModule)
  }, 
  {
    path: 'sales-report',
    loadChildren: () => import('./sales-report/sales-report.module').then(m => m.SalesReportPageModule)
  },
  {
    path: 'asset-search',
    loadChildren: () => import('./asset-search/asset-search.module').then(m => m.AssetSearchModule)
  },
 {
    path: 'delar-report',
    loadChildren: () => import('./delar-report/delar-report.module').then(m => m.DelarReportModule)
  },
  { path: 'profile-detail', loadChildren: './profile-detail/profile-detail.module#ProfileDetailPageModule' },
  { path: 'assert-status-list/:type', loadChildren: './new-dashboard/assert-status-list/assert-status-list.module#AssertStatusListPageModule' },
  { path: 'check-imei', loadChildren: './check-imei/check-imei.module#CheckImeiPageModule' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DelarApplicationRoutingModule { }
